import styled from 'styled-components';

export const Container = styled.section`
  background: linear-gradient(
    to right top,
    #000113,
    #1f1d37,
    #40325c,
    #6a4580,
    #9b57a1
  );

  min-height: 100vh;

  display: flex;
  justify-content: center;
  position: relative;

  .vortexLight {
    top: 0;
    left: 0;
    transform: rotateX(180deg);
  }

  .vortexDark {
    bottom: 0;
    right: 0;
    transform: rotateX(180deg);
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  padding: 0 30px;

  .men {
    width: 400px;
  }

  .content {
    height: 600px;

    h1 {
      text-align: right;
      margin-bottom: 50px;
    }

    .functionalities {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & + div {
          margin-top: 30px;
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 85px;
        height: 100px;

        margin-left: 50px;

        img {
          width: 70px;
        }

        span {
          text-transform: uppercase;
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 1250px) {
    flex-direction: column;

    .men {
      margin: 60px 0;
    }

    .content {
      h1 {
        text-align: center;
      }

      .functionalities {
        align-items: center;

        .card {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  @media (max-width: 850px) {
    .content {
      height: auto;
      padding-bottom: 100px;
    }
  }

  @media (max-width: 400px) {
    .content {
      .functionalities {
        .card {
          margin-left: 30px;
        }
      }
    }
  }
`;
