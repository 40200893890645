import React from 'react';

import { productsKit, vortexDark, vortexLight } from '~/assets/images';
import { Title, Button, Description } from '~/components';

import { Container, Content } from './styles';

function Presentation() {
  return (
    <>
      <Container>
        <img src={vortexDark} className="vortexDark vortex" alt="vortex" />
        <Content>
          <div className="content">
            <div className="information">
              <Title variant="secondary">Viemos para revolucionar!</Title>
              <Description variant="secondary">
                AquisiPay é maquina, é conta, é tudo!
              </Description>
              <Description variant="secondary">
                Aproveite e solicite agora mesmo!
              </Description>
              {/* <Button outline width="200px">
                Eu quero
              </Button> */}
            </div>
            <img src={productsKit} alt="products kit" />
          </div>
        </Content>
        <img src={vortexLight} className="vortexLight vortex" alt="vortex" />
      </Container>
    </>
  );
}

export default Presentation;
