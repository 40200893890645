import styled from 'styled-components';

export const Container = styled.section`
  background: #f3dcf3;
  min-height: 100vh;

  display: flex;
  justify-content: center;

  position: relative;

  .vortexDark {
    top: 0;
    right: 0;
  }

  .vortexLight {
    bottom: 0;
    left: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;

  z-index: 1;
  padding: 0 30px;

  .header {
    width: 100%;
    padding: 30px 0;

    img {
      width: 200px;
    }
  }

  .title {
    width: 100%;
    max-width: 780px;
    text-align: right;
    margin-left: auto;
    margin-top: -20px;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .steps {
      display: flex;
      flex-direction: row;

      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }

        h1 {
          color: ${({ theme }) => theme.palette.primary};
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
    }

    .plataforms {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        font-size: 20px;
        color: #444;
        font-family: QanelasBold;
        margin-bottom: 20px;
      }

      .images {
        img {
          width: 180px;

          & + img {
            margin-left: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 1250px) {
    padding-bottom: 100px;

    .title {
      margin: 50px 0;
    }

    .content {
      flex-direction: column;
      align-items: center;

      .steps {
        margin-bottom: 50px;
      }

      .plataforms {
        align-items: center;
      }
    }
  }

  @media (max-width: 850px) {
    .title {
      h1 {
        text-align: center;
      }
    }

    .content {
      .steps {
        flex-direction: column;

        .step {
          margin-right: 0;
          margin-bottom: 50px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    .plataforms {
      .images {
        display: flex;
        flex-direction: column;

        img {
          width: 180px;

          & + img {
            margin-left: 0 !important;
            margin-top: 20px;
          }
        }
      }
    }
  }
`;
