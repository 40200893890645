import { createGlobalStyle } from 'styled-components';

import QanelasBlack from '~/assets/fonts/qanelas-black.otf';
import QanelasBold from '~/assets/fonts/qanelas-bold.otf';
import Qanelas from '~/assets/fonts/qanelas.otf';

export default createGlobalStyle`
  @font-face {
    font-family: Qanelas;
    src: url(${QanelasBlack});
  }

  @font-face {
    font-family: 'Qanelas';
    src: local('Qanelas'), url(${Qanelas});
  }

  @font-face {
    font-family: 'QanelasBold';
    src: local('QanelasBold'), url(${QanelasBold});
  }

  @font-face {
    font-family: 'QanelasBlack';
    src: local('QanelasBlack'), url(${QanelasBlack});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Qanelas', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.palette.secondary};

    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(111, 52, 107, 0.50);
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.primary};
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  html, body, #root {
    width: 100%;
    overflow-x: hidden;
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-family: QanelasBlack;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    transition: opacity 0.6s;

    &:hover {
      opacity: 0.6;
    }
  }

  input {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  a {
    text-decoration: none;
  }

  .vortex {
    max-width: 700px;
    position: absolute;

    @media (max-width: 900px) {
      width: 500px;
    }
  }
`;
