import React from 'react';

import {
  logo,
  vortexLight,
  vortexDark,
  dowload,
  register,
  use,
  app,
  google,
} from '~/assets/images';
import { Title } from '~/components';

import { Container, Content } from './styles';

function Simplicity() {
  return (
    <Container>
      <img src={vortexDark} className="vortexDark vortex" alt="vortexDark" />

      <Content>
        <div className="header">
          <img src={logo} alt="AquisiPay" />
        </div>

        <div className="title">
          <Title light>Mais simples do que tomar um café ou ler jornal!</Title>
        </div>

        <div className="content">
          <div className="steps">
            <div className="step">
              <h1>Baixou</h1>
              <img src={dowload} alt="dowload" />
            </div>
            <div className="step">
              <h1>Cadastrou</h1>
              <img src={register} alt="register" />
            </div>
            <div className="step">
              <h1>Usou!</h1>
              <img src={use} alt="use" />
            </div>
          </div>

          {/* <div className="plataforms">
            <span>Baixe agora mesmo!</span>
            <div className="images">
              <img src={app} alt="app store" />
              <img src={google} alt="google" />
            </div>
          </div> */}
        </div>
      </Content>

      <img src={vortexLight} className="vortexLight vortex" alt="vortexLight" />
    </Container>
  );
}

export default Simplicity;
