import React, { useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';

import { Container } from './styles';

function Menu({ open, setOpen }) {
  useEffect(() => {
    if (open) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <Container open={open}>
      <button
        className="close-button"
        type="button"
        onClick={() => setOpen(false)}
      >
        <RiCloseLine />
      </button>
      <div>
        {/* <button className="nav-button" type="button">
          Sobre nós
        </button>
        <button className="nav-button" type="button">
          Cases
        </button>
        <button className="nav-button" type="button">
          Fale Conosco
        </button> */}
        <a
          href="http://portal.aquisipay.com.br/"
          target="_blank"
          rel="noreferrer"
          className="nav-button"
        >
          Acesse seu portal
        </a>
      </div>
    </Container>
  );
}

export default Menu;
