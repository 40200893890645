import styled from 'styled-components';

export const Container = styled.section`
  background-image: linear-gradient(
    to right top,
    #0d0923,
    #261e40,
    #452f5d,
    #68417a,
    #905296
  );
  min-height: 100vh;

  display: flex;
  justify-content: center;
  position: relative;

  .vortexDark {
    right: 0;
    top: 15%;
  }

  .vortexLight {
    left: 0;
    bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  .informations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 15%;

    .card {
      display: flex;
      flex-direction: row;
      align-items: center;

      height: 100%;

      padding: 0 20px;

      img {
        margin-right: 20px;
        width: 60px;
      }
    }
  }

  .content {
    height: 85%;
    width: 100%;
    max-width: 1360px;
    padding: 100px 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .texts {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 500px;
    }
  }

  @media (max-width: 1250px) {
    .informations {
      flex-direction: column;
      height: auto;
      padding: 0 30px;

      .card {
        border-radius: 10px;
        width: 100%;
        max-width: 600px;
        height: 100px;
        margin-top: 20px;
      }
    }

    .content {
      flex-direction: column;
      justify-content: center;
      height: auto;

      img {
        margin-top: 80px;
        width: 100%;
      }
    }
  }

  @media (max-width: 620px) {
    .informations {
      .card {
        height: 150px;

        .paragraph {
          strong {
            font-size: 15px;
          }

          p {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .informations {
      .card {
        height: 170px;

        .paragraph {
          strong {
            font-size: 14px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
