import styled, { keyframes } from 'styled-components';

const slideToRight = keyframes`
  from {
    transform: translate(-2000px)
  }
  to {
    transform: translate(0)
  }
`;

export const Container = styled.section`
  background: linear-gradient(
    to right top,
    #000113,
    #1f1d37,
    #40325c,
    #6a4580,
    #9b57a1
  );

  min-height: 100vh;

  display: flex;
  justify-content: center;
  padding: 0 30px 100px 30px;

  position: relative;

  .vortexDark {
    right: 0;
    top: 0;
  }

  .vortexLight {
    left: 0;
    bottom: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;

  z-index: 1;

  margin-top: 170px;

  .content {
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      height: 510px;

      h1 {
        font-size: 75px;
        text-transform: uppercase;

        animation: ${slideToRight} 1s;
      }

      span {
        font-size: 22px;
        line-height: 30px;
        animation: ${slideToRight} 1.2s;

        & + span {
          margin-bottom: 20px;
          animation: ${slideToRight} 1.4s;
        }
      }

      button {
        animation: ${slideToRight} 1.6s;
      }
    }

    img {
      width: 580px;
    }
  }

  @media (max-width: 1250px) {
    .content {
      flex-direction: column;
      justify-content: center;

      .information {
        height: auto;
      }
    }
  }

  @media (max-width: 800px) {
    margin-top: 150px;

    .content {
      .information {
        align-items: center;

        justify-content: center;

        h1 {
          text-align: center;
          font-size: 70px;
        }

        span {
          text-align: center;
        }
      }

      img {
        width: 500px;
        margin-top: 50px;
      }
    }
  }

  @media (max-width: 580px) {
    margin-top: 140px;

    .content {
      .information {
        h1 {
          font-size: 60px;
        }

        span {
          font-size: 20px;
        }
      }

      img {
        width: 450px;
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 100px;

    .content {
      .information {
        h1 {
          font-size: 47px;
        }

        span {
          font-size: 19px;
        }
      }

      img {
        width: 400px;
      }
    }
  }

  @media (max-width: 420px) {
    .content {
      .information {
        h1 {
          font-size: 40px;
        }

        span {
          font-size: 16px;
        }
      }

      img {
        width: 330px;
      }
    }
  }
`;
