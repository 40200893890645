import React from 'react';

import { useMedia } from 'use-media';

import {
  men,
  paymentsIcon,
  transferIcon,
  walletIcon,
  chargeIcon,
  servicesIcon,
  pixIcon,
  levelupIcon,
  netflixIcon,
  moneyIcon,
  rechargeIcon,
  extractIcon,
  vortexDark,
  vortexLight,
} from '~/assets/images';
import { Title } from '~/components';

import { Container, Content } from './styles';

function Functionalities() {
  const maxWidth850 = useMedia({ maxWidth: '850px' });

  return (
    <Container>
      <img src={vortexDark} className="vortexDark vortex" alt="vortex" />
      <Content>
        <img className="men" src={men} alt="men" />
        <div className="content">
          <Title variant="secondary" uppercase={false} light>
            Chega de filas em bancos
            <br /> faça tudo pelo nosso app!
          </Title>
          <div className="functionalities">
            {maxWidth850 ? (
              <>
                <div className="div">
                  <div className="card">
                    <img src={paymentsIcon} alt="payments" />
                    <span>Pagamentos</span>
                  </div>
                  <div className="card">
                    <img src={transferIcon} alt="transfer" />
                    <span>Transferências</span>
                  </div>
                  <div className="card">
                    <img src={walletIcon} alt="wallet" />
                    <span>Wallet</span>
                  </div>
                </div>

                <div className="div">
                  <div className="card">
                    <img src={chargeIcon} alt="charge" />
                    <span>Cobrança</span>
                  </div>
                  <div className="card">
                    <img src={servicesIcon} alt="services" />
                    <span>Serviços</span>
                  </div>
                  <div className="card">
                    <img src={pixIcon} alt="pix" />
                    <span>Pix</span>
                  </div>
                </div>

                <div className="div">
                  <div className="card">
                    <img src={levelupIcon} alt="levelUp" />
                    <span>Level Up</span>
                  </div>
                  <div className="card">
                    <img src={netflixIcon} alt="netflix" />
                    <span>Netflix</span>
                  </div>
                  <div className="card">
                    <img src={moneyIcon} alt="money" />
                    <span>Depositos</span>
                  </div>
                </div>

                <div className="div">
                  <div className="card">
                    <img src={transferIcon} alt="transfer" />
                    <span>Ted</span>
                  </div>
                  <div className="card">
                    <img src={rechargeIcon} alt="recharge" />
                    <span>Recarga de celular</span>
                  </div>
                  <div className="card">
                    <img src={extractIcon} alt="extract" />
                    <span>Extrato</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="div">
                  <div className="card">
                    <img src={paymentsIcon} alt="payments" />
                    <span>Pagamentos</span>
                  </div>
                  <div className="card">
                    <img src={transferIcon} alt="transfer" />
                    <span>Transferências</span>
                  </div>
                  <div className="card">
                    <img src={walletIcon} alt="wallet" />
                    <span>Wallet</span>
                  </div>
                  <div className="card">
                    <img src={chargeIcon} alt="charge" />
                    <span>Cobrança</span>
                  </div>
                  <div className="card">
                    <img src={servicesIcon} alt="services" />
                    <span>Serviços</span>
                  </div>
                  <div className="card">
                    <img src={pixIcon} alt="pix" />
                    <span>Pix</span>
                  </div>
                </div>
                <div className="div">
                  <div className="card">
                    <img src={levelupIcon} alt="levelUp" />
                    <span>Level Up</span>
                  </div>
                  <div className="card">
                    <img src={netflixIcon} alt="netflix" />
                    <span>Netflix</span>
                  </div>
                  <div className="card">
                    <img src={moneyIcon} alt="money" />
                    <span>Depositos</span>
                  </div>
                  <div className="card">
                    <img src={transferIcon} alt="transfer" />
                    <span>Ted</span>
                  </div>
                  <div className="card">
                    <img src={rechargeIcon} alt="recharge" />
                    <span>Recarga de celular</span>
                  </div>
                  <div className="card">
                    <img src={extractIcon} alt="extract" />
                    <span>Extrato</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Content>
      <img src={vortexLight} className="vortexLight vortex" alt="vortex" />
    </Container>
  );
}

export default Functionalities;
