import styled from 'styled-components';

export const Container = styled.section`
  background: #130d2e;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 70px 30px;

  .address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    padding-right: 40px;

    img {
      width: 200px;
    }

    .info {
      color: ${({ theme }) => theme.palette.primary};

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      margin-left: 40px;

      h1 {
        font-family: Qanelas;
      }
    }
  }

  .navbar {
    padding-left: 40px;

    border-left-width: 2px;
    border-left-color: ${({ theme }) => theme.palette.secondary};
    border-left-style: solid;

    .nav-button {
      color: ${({ theme }) => theme.palette.secondary};

      margin-bottom: 7px;

      display: flex;
      flex-direction: column;

      transition: 0.5s opacity;

      &:hover {
        opacity: 0.8;
      }
    }

    .socialNetworks {
      margin-top: 10px;

      button {
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 860px) {
    flex-direction: column;
    padding: 50px 30px;

    .address {
      flex-direction: column;
      padding-right: 0;

      .info {
        margin: 30px 0;

        h1 {
          text-align: center;
          width: 100%;
        }

        span {
          text-align: center;
          width: 100%;
        }
      }
    }

    .navbar {
      border: none;
      padding-left: 0;

      .nav-button {
        margin-right: 0;
        margin-bottom: 10px;

        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .socialNetworks {
        margin-top: 30px;
      }
    }
  }
`;
