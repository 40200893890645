import styled from 'styled-components';

export const Container = styled.section`
  background: ${({ theme }) => theme.palette.secondary};

  min-height: 100vh;
  position: relative;

  display: flex;
  justify-content: center;

  padding: 0 30px;
  padding-bottom: 100px;

  .vortex {
    position: absolute;
    transform: rotateX(180deg);
  }

  .vortexLight {
    left: 0;
    top: 0;
  }

  .vortexDark {
    right: 0;
    bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1300px;
  z-index: 1;

  .header {
    width: 100%;

    display: flex;
    justify-content: flex-end;

    padding: 30px 0;

    img {
      width: 200px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 89%;

    .information {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 580px;
    }
  }

  @media (max-width: 1250px) {
    .content {
      flex-direction: column;
      justify-content: center;

      .information {
        align-items: flex-end;

        h1 {
          text-align: right;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .content {
      .information {
        align-items: center;

        justify-content: center;

        h1 {
          text-align: center;
        }

        span {
          text-align: center;
        }
      }

      img {
        width: 500px;
        margin-top: 50px;
      }
    }
  }

  @media (max-width: 580px) {
    .content {
      .information {
        span {
          font-size: 20px;
        }
      }

      img {
        width: 450px;
      }
    }
  }

  @media (max-width: 500px) {
    .content {
      .information {
        span {
          font-size: 19px;
        }
      }

      img {
        width: 400px;
      }
    }
  }

  @media (max-width: 420px) {
    .content {
      .information {
        h1 {
          font-size: 40px;
        }

        span {
          font-size: 16px;
        }
      }

      img {
        width: 330px;
      }
    }
  }
`;
