import styled, { keyframes } from 'styled-components';

const slideToRight = keyframes`
  from {
    transform: translate(-2000px)
  }
  to {
    transform: translate(0)
  }
`;

const slideToLeft = keyframes`
  from {
    transform: translate(2000px);
  }
  to {
    transform: translate(0);
  }
`;

export const Container = styled.div`
  width: 100%;

  position: absolute;
  z-index: 2;
  padding: 0 30px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 30px 0;

  img {
    width: 200px;
    animation: ${slideToRight} 1s;
  }

  .navbar {
    button {
      animation: ${slideToLeft} 1s;

      & + button {
        animation: ${slideToLeft} 1.3s;
      }

      & + button + button {
        animation: ${slideToLeft} 1.6s;
      }

      & + button + button + button {
        animation: ${slideToLeft} 1.9s;
      }
    }

    svg {
      color: ${({ theme }) => theme.palette.secondary};
      font-size: 30px;
    }
  }
`;
