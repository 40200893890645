import React from 'react';

import { Container } from './styles';

function Button({
  outline = false,
  last = false,
  width = 'auto',
  children,
  ...rest
}) {
  return (
    <Container outline={outline} last={last} width={width} {...rest}>
      {children}
    </Container>
  );
}

export default Button;
