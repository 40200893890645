import React from 'react';

import {
  card,
  allDayIcon,
  cardIcon,
  securityIcon,
  vortexDark,
  vortexLight,
} from '~/assets/images';
import { Title, Description, Button } from '~/components';

import { Container, Content } from './styles';

function Card() {
  return (
    <Container>
      <img src={vortexDark} className="vortexDark vortex" alt="vortexDark" />
      <Content>
        <div className="informations">
          <div className="card" style={{ background: '#573665' }}>
            <img src={allDayIcon} alt="allDayIcon" />
            <div className="paragraph">
              <strong>Realize transferencia a qualquer momento</strong>
              <p>
                Transfira um valor da sua conta digital, para uma outra em
                qualquer hora do dia, e tudo isso sem nenhum custo.
              </p>
            </div>
          </div>
          <div className="card" style={{ background: '#c54d9d' }}>
            <img src={cardIcon} alt="cardIcon" />
            <div className="paragraph">
              <strong>Cartão personalizado</strong>
              <p>
                Ao abrir sua conta, você receberá o cartão personalizado para
                realizar saques efetuar pagamentos e muito mais...
              </p>
            </div>
          </div>
          <div className="card" style={{ background: '#661b5f' }}>
            <img src={securityIcon} alt="securityIcon" />
            <div className="paragraph">
              <strong>Seu dinheiro protegido como nunca</strong>
              <p>
                Seguimos totalmente as regras impostas pelo BANCO CENTRAL, além
                disso, possuímos as principais certificações mundiais de
                segurança da informação.
              </p>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="texts">
            <Title variant="secondary">Agilidade?</Title>
            <Title variant="secondary">Também temos</Title>
            <Description variant="secondary">
              Efetue saques, compras pela internet...
            </Description>
            <Description variant="secondary">
              Com o Aquisicard tudo fica mais fácil, aproveite!
            </Description>
            {/* <Button outline width="200px">
              Abrir conta
            </Button> */}
          </div>
          <img src={card} alt="AquisiCard" />
        </div>
      </Content>
      <img src={vortexLight} className="vortexLight vortex" alt="vortexLight" />
    </Container>
  );
}

export default Card;
