import React from 'react';

import { ThemeProvider } from 'styled-components';

import { Header } from '~/components';
import {
  PerfectForYou,
  Presentation,
  Simplicity,
  Functionalities,
  Card,
  Footer,
} from '~/sections';
import GlobalStyle from '~/styles/global';
import theme from '~/styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <main style={{ position: 'relative' }}>
        <Header />
        <Presentation />
        <PerfectForYou />
        <Simplicity />
        <Functionalities />
        <Card />
        <Footer />
      </main>
    </ThemeProvider>
  );
}

export default App;
