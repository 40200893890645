import styled from 'styled-components';

export const StyledDescription = styled.span`
  color: ${(props) => props.theme.palette[props.variant]};
  font-size: 22px;
  line-height: 30px;

  & + span {
    margin-bottom: 20px;
  }

  @media (max-width: 580px) {
    font-size: 20px;
  }

  @media (max-width: 500px) {
    font-size: 19px;
  }

  @media (max-width: 420px) {
    font-size: 17px;
  }
`;
