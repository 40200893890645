import styled, { keyframes } from 'styled-components';

const backdrodFade = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  position: fixed;
  background: rgba(102, 27, 95, 0.95);
  z-index: 100;
  justify-content: center;
  align-items: center;
  animation: ${backdrodFade} 0.4s;

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 21px;
    margin-top: 25px;

    svg {
      color: ${({ theme }) => theme.palette.secondary};
      height: 45px;
      width: 45px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    height: 35%;

    .nav-button {
      margin-bottom: 10px;

      color: ${({ theme }) => theme.palette.secondary};
      font-size: 25px;
      font-family: QanelasBold;

      transition: opacity 0.8s;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
`;
