import styled from 'styled-components';

export const StyledTitle = styled.h1`
  color: ${(props) => props.theme.palette[props.variant]};

  font-size: ${(props) => (props.light ? '60px' : '75px')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};

  @media (max-width: 800px) {
    font-size: ${(props) => (props.light ? '45px' : '70px')};
  }

  @media (max-width: 580px) {
    font-size: ${(props) => (props.light ? '35px' : '60px')};
  }

  @media (max-width: 500px) {
    font-size: ${(props) => (props.light ? '30px' : '47px')};
  }

  @media (max-width: 420px) {
    font-size: ${(props) => (props.light ? '25px;' : '40px')};
  }
`;
