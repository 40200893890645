import React from 'react';

import { digitalAccount, logo, vortexDark, vortexLight } from '~/assets/images';
import { Button, Description, Title } from '~/components';

import { Container, Content } from './styles';

function PerfectForYou() {
  return (
    <Container>
      <img src={vortexLight} className="vortexLight vortex" alt="vortex" />

      <Content>
        <div className="header">
          <img src={logo} alt="AquisiPay" />
        </div>
        <div className="content">
          <div className="information">
            <Title>A CONTA DIGITAL perfeita para você!</Title>
            <Description>É de graça, é completa!</Description>
            <Description>Aproveite e abra sua conta agora mesmo!</Description>
            {/* <Button outline width="200px">
              Abrir conta
            </Button> */}
          </div>
          <img src={digitalAccount} alt="digital account" />
        </div>
      </Content>

      <img src={vortexDark} className="vortexDark vortex" alt="vortex" />
    </Container>
  );
}

export default PerfectForYou;
