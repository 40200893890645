import styled, { css } from 'styled-components';

export const Container = styled.button`
  box-shadow: inset;
  border-radius: 50px;

  font-size: 18px;
  font-family: QanelasBold;
  color: #fff;
  width: ${(props) => props.width};

  padding: 10px 20px;
  padding-right: ${(props) => props.last && '0'};

  ${(props) =>
    props.outline &&
    css`
      background: ${({ theme }) => theme.palette.primary};
    `}
`;
