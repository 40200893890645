import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

import { whiteLogo } from '~/assets/images';
import theme from '~/styles/theme';

import { Container } from './styles';

function Footer() {
  return (
    <Container>
      <div className="address">
        <img src={whiteLogo} alt="AquisiCard" />
        <div className="info">
          <span>Telefone: (19)3893-7146 - (19)3852-4352</span>
          <span>Rua: XV de Novembro, 427 - Centro</span>
          <span>Pedreira/SP CEP: 13920-000</span>
          <span>contato@aquisipay.com.br</span>
        </div>
      </div>
      {/* <div className="navbar">
        <button type="button" className="nav-button">
          Políticas de uso
        </button>
        <button type="button" className="nav-button">
          Trabalhe conosco
        </button>
        <button type="button" className="nav-button">
          Legislação
        </button>
        <button type="button" className="nav-button">
          A empresa
        </button>
        <div className="socialNetworks">
          <button type="button">
            <FaFacebookF color={theme.palette.primary} size={30} />
          </button>
          <button type="button">
            <FaTwitter color={theme.palette.primary} size={30} />
          </button>
          <button type="button">
            <FaInstagram color={theme.palette.primary} size={30} />
          </button>
        </div>
      </div> */}
    </Container>
  );
}

export default Footer;
