import React from 'react';

import { StyledTitle } from './styles';

function Title({
  children,
  variant = 'primary',
  uppercase = true,
  light = false,
}) {
  return (
    <StyledTitle variant={variant} uppercase={uppercase} light={light}>
      {children}
    </StyledTitle>
  );
}

export default Title;
