import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

import { useMedia } from 'use-media';

import { whiteLogo } from '~/assets/images';
import { Button, Menu } from '~/components';

import { Container, Content } from './styles';

function Header() {
  const [open, setOpen] = useState(false);
  const maxWidth850 = useMedia({ maxWidth: '850px' });

  return (
    <>
      <Menu open={open} setOpen={setOpen} />
      <Container>
        <Content>
          <img src={whiteLogo} alt="Aquisipay" />
          <div className="navbar">
            {maxWidth850 ? (
              <button onClick={() => setOpen(true)}>
                <GiHamburgerMenu />
              </button>
            ) : (
              <>
                {/* <Button>Sobre nós</Button>
                <Button>Contato</Button> */}
                <a
                  href="http://portal.aquisipay.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button outline>Acesse seu portal</Button>
                </a>
              </>
            )}
          </div>
        </Content>
      </Container>
    </>
  );
}

export default Header;
